<template>
  <div>
    <SectionCard
      label="Выберите сотрудников, для которых необходимо создать пользователей из Справочника Сотрудников"
      :class="$style.root"
      :loading="pending"
    >
      <VDataTable
        fixed-header
        height="calc(100vh - 321px)"
        :value="value"
        show-select
        :headers="headers"
        :server-items-length="list.navCount"
        :page="list.navPage"
        :size="list.navPageSize"
        :items="list.items"
        :loading="pending"
        :item-class="getClassByRow"
        :mobile-breakpoint="0"
        @input="val => value = val"
        @update:page="page => onPagination({ page })"
        @update:items-per-page="size => onPagination({ size })"
      >
        <template v-slot:body.prepend>
          <FilterRow :items="headers" @input="onPagination" show-select />
        </template>
      </VDataTable>
    </SectionCard>
    <VFooter app>
      <div class="py-3 grow">
        <VBtn class="mr-6" outlined depressed @click="onBack" :disabled="pending">
          <VIcon>mdi-arrow-left</VIcon>
          Назад к списку
        </VBtn>
        <VBtn color="primary" depressed class="mr-3" :disabled="isEmpty(value) || pending" @click="onAdd">
          Создать пользователей
        </VBtn>
        <VBtn color="primary" depressed class="mr-3" :disabled="pending" @click="onReset">
          Отмена
        </VBtn>
      </div>
    </VFooter>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import SectionCard from '@/components/user/SectionCard/SectionCard.vue';
import {debounce, get, isEmpty, isEqual, map, pickBy} from 'lodash-es';
import FilterRow from '@/components/inspect/FilterRow/FilterRow.vue';

export default {
  name: 'AccountUsersDatabase',
  components: { FilterRow, SectionCard },
  props: {
    id: { type: String },
    page: { type: String },
    size: { type: String },
    filter: { type: Object },
  },
  data() {
    return {
      value: [],
      headers: [
        { text: 'Дата создания', value: 'dateCreate', sortable: false },
        { text: 'ФИО', value: 'fio', sortable: false },
        { text: 'Должность', value: 'position', sortable: false},
        { text: 'Дата рождения', value: 'dateBirth', sortable: false},
      ],
    }
  },
  computed: {
    ...mapGetters({
      current: 'user/current',
      pending: 'accountUsers/pending',
      list: 'accountUsers/employee',
    }),
  },
  methods: {
    isEmpty,
    ...mapActions({
      getAccountUsersEmployees: 'accountUsers/getAccountUsersEmployees',
      addAccountUsersEmployees: 'accountUsers/addAccountUsersEmployees',
    }),
    onUpdateState: debounce(function() {
      this.update()
    }, 500),
    async update() {
      const { page, size, filter } = this;
      await this.getAccountUsersEmployees({ accountId: get(this.current, 'account.accountId'), page, size, filter });
    },
    async onBack() {
      if (!isEmpty(this.value)) {
        const { isConfirmed } = await this.$swal("Вы уверены что хотите вернуться? Не сохранённые данные будут утеряны");
        if (!isConfirmed) return;
      }
      this.$router.push({ name: 'account-users/AccountUsersView'})
    },
    onReset() {
      this.value = [];
    },
    getClassByRow() {
      return '';
    },
    async onAdd() {
      const result = await this.addAccountUsersEmployees({
        accountId: get(this.current, 'account.accountId'),
        id: map(this.value, ({ id }) => id),
      })

      if (result) {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Пользователи успешно созданы',
        });

        this.$router.push({ name: 'account-users/AccountUsersView'})
      }
    },
    onPagination(params) {
      const { query } = this.$route;
      const path = pickBy({ ...query, ...params }, Boolean);
      if (!isEqual(query, path)) this.$router.push({ query: path });
    },
  },
  watch: {
    page: function () {
      this.onUpdateState();
    },
    size: function () {
      this.onUpdateState();
    },
    filter: {
      deep: true,
      handler: function () {
        this.onUpdateState();
      }
    }
  },
  beforeMount() {
    this.update();
  }
}
</script>
<style module lang="scss">
.root {
  margin-bottom: 0;
  th, td {
    width: 100px !important;
    &:nth-child(1) {
      width: 1px !important;
      max-width: 1px !important;
      min-width: 1px !important;
    }
  }
}
</style>
