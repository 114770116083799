<template>
  <AccountUsersDatabase v-if="fromDatabase" :page="page" :size="size" :filter="filter"/>
  <AccountUsersApplication v-else/>
</template>
<script>
import AccountUsersApplication from '@/components/account-users/AccountUsers/components/AccountUsersApplication.vue';
import AccountUsersDatabase from '@/components/account-users/AccountUsers/components/AccountUsersDatabase.vue';

export default {
  name: 'AccountUsersCreateView',
  components: { AccountUsersApplication, AccountUsersDatabase },
  props: {
    fromDatabase: { type: Boolean },
    page: { type: Number, default: 1 },
    size: { type: Number, default: 20 },
    filter: { type: Object }
  }
}
</script>
